import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MeditationCardComponent from './MeditationCardComponent';
import ZenSpinner from './ZenSpinner'; // Importer ZenSpinner

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const TechniqueListComponent = () => {
  const navigate = useNavigate();
  const [techniques, setTechniques] = useState([]);
  const [loading, setLoading] = useState(true); // État de chargement

  useEffect(() => {
    const fetchTechniques = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/techniques/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('La récupération des techniques a échoué');
        }
        const data = await response.json();
        setTechniques(data);
        setLoading(false); // Désactiver le chargement une fois les données récupérées
      } catch (error) {
        console.error(error.message);
        setLoading(false); // Désactiver le chargement même en cas d'erreur
      }
    };

    fetchTechniques();
  }, []);

  const handleCardClick = (id) => {
    navigate(`/technique/${id}`);
  };

  if (loading) {
    return <ZenSpinner />; // Afficher le ZenSpinner pendant le chargement
  }

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
      {techniques.map((technique) => (
        <MeditationCardComponent
          key={technique._id}
          image={technique.image || ''}
          title={technique.title}
          category={technique.category}
          duration={technique.duration}
          description={technique.description}
          istechniqueList={true}
          showIcon={false}
          onClick={() => handleCardClick(technique._id)}
        />
      ))}
    </Box>
  );
};

export default TechniqueListComponent;
