import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Typography, Link, styled, TextField, Button, MobileStepper, Alert, Grid, Fade, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { formatEmail } from '../utils/emailUtils';
import useDeviceInfo from '../utils/useDeviceInfo';

const AlertContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: '50%',
  width: '90%',
  transform: 'translateX(-50%)',
  transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
  opacity: 0,
  zIndex: 1000,
  '&.alert-visible': {
    transform: 'translateX(-50%) translateY(-20px)',
    opacity: 1,
  },
}));

const avatarBasePath = './avatar/';
const avatars = [
  '001.jpg',
  '002.jpg',
  '003.jpg',
  '004.jpg',
  '005.jpg',
  '006.jpg',
];

const questions = [
  { question: "Comment tu t'appelles ?", name: "firstName", type: "text" },
  { question: "Quel est ton nom de famille ?", name: "lastName", type: "text" },  // Nouvelle question ajoutée ici
  { question: "Quel est ton email ?", name: "email", type: "email" },
  { question: "Quel est ton numéro de téléphone ?", name: "phone", type: "tel" },
  { question: "Choisis ton avatar", name: "avatar", type: "avatar" },
  { question: "Crée un mot de passe", name: "password", type: "password" },
  { question: "Retape le mot de passe", name: "confirmPassword", type: "password" },
];

const SignUpPage = () => {
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);
  const [step, setStep] = useState(0);
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',  // Ajout du champ lastName ici
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    birthDate: '',
    avatar: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState('');

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>/\\[\]]/.test(password);
    const isValid = password.length >= minLength && hasUpperCase && hasNumber && hasSpecialChar;

    let helperText = '';
    if (!isValid) {
      helperText = 'Le mot de passe doit contenir :';
      if (password.length < minLength) helperText += ' au moins 8 caractères,';
      if (!hasUpperCase) helperText += ' une majuscule,';
      if (!hasNumber) helperText += ' un chiffre,';
      if (!hasSpecialChar) helperText += ' un caractère spécial,';
    }

    setPasswordHelperText(helperText);
    return isValid;
  };

  const handleSignUp = async () => {
    if (!validatePassword(formData.password)) {
      setErrorMessage("Le mot de passe ne respecte pas les critères requis.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setErrorMessage('');
      }, 4000);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Les mots de passe ne correspondent pas.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setErrorMessage('');
      }, 4000);
      return;
    }

    try {
      const formattedEmail = formatEmail(formData.email);
      await signUp({ ...formData, email: formattedEmail });
      navigate('/waiting');
    } catch (error) {
      console.error(error);
      setErrorMessage("Erreur lors de l'inscription. Veuillez réessayer.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setErrorMessage('');
      }, 4000);
    }
  };

  const handleBackClick = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate('/');
    }
  };

  const handleNextClick = () => {
    if (step < questions.length - 1) {
      setStep(step + 1);
    } else {
      handleSignUp();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') validatePassword(value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleAvatarSelect = (avatar) => {
    setFormData({ ...formData, avatar: `${avatarBasePath}${avatar}` });
  };

  return (
    <>
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 6 }}>
        <Box sx={{ width: '100%', maxWidth: 400, minHeight: 150, position: 'relative' }}>
          {questions.map((question, index) => (
            <Fade
              key={index}
              in={step === index}
              timeout={{ enter: 800, exit: 800 }}
              mountOnEnter
              unmountOnExit
            >
              <Box textAlign="center" sx={{ position: 'absolute', top: -40, left: 0, right: 0 }}>
                <Typography variant="h5">{question.question}</Typography>
                {question.type === 'avatar' ? (
                  <Grid container spacing={2} sx={{ mt: 1, justifyContent: 'center' }}>
                    {avatars.map((avatar) => (
                      <Grid item xs={3} key={avatar}>
                        <img
                          src={`${avatarBasePath}${avatar}`}
                          alt={avatar}
                          style={{
                            width: '100%',
                            cursor: 'pointer',
                            borderRadius: '50%',
                            border: formData.avatar === `${avatarBasePath}${avatar}` ? '6px solid #000' : '3px solid #000'
                          }}
                          onClick={() => handleAvatarSelect(avatar)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <TextField
                    fullWidth
                    type={question.name === 'password' ? (showPassword ? 'text' : 'password') : (question.name === 'confirmPassword' ? (showConfirmPassword ? 'text' : 'password') : question.type)}
                    name={question.name}
                    value={formData[question.name]}
                    onChange={handleChange}
                    margin="normal"
                    InputProps={{
                      endAdornment: (question.name === 'password' || question.name === 'confirmPassword') && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={question.name === 'password' ? handleClickShowPassword : handleClickShowConfirmPassword}
                          >
                            {(question.name === 'password' ? showPassword : showConfirmPassword) ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={question.name === 'password' && passwordHelperText ? passwordHelperText : ''}
                  />
                )}
              </Box>
            </Fade>
          ))}
        </Box>
        <Box sx={{ position: 'absolute', pb: paddingBottom, bottom: 0, width: '100%', bgcolor: 'background.paper' }}>
          <Box display="flex" sx={{ px: 2, mb: 2 }} justifyContent="space-between">
            <Button onClick={handleBackClick}>Retour</Button>
            <Button variant="contained" onClick={handleNextClick}>{step < questions.length - 1 ? 'Suivant' : "S'inscrire"}</Button>
          </Box>
          <Box sx={{ width: '100%', mt: 2 }}>
            <Box sx={{ width: `${(step + 1) / questions.length * 100}%`, bgcolor: 'primary.main', height: 8, transition: 'width 0.5s' }} />
          </Box>
        </Box>
      </Box>
      <AlertContainer sx={{ pb: paddingBottom }} className={alertVisible ? 'alert-visible' : ''}>
        {errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
      </AlertContainer>
    </>
  );
};

export default SignUpPage;
