import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography, Grid, useTheme, Skeleton, Button } from '@mui/material';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import Lottie from 'react-lottie-player';
import pulseAnimation from '../assets/pulse.json'; 
import useDeviceInfo from '../utils/useDeviceInfo';
import TopMeditations from '../components/meditations/TopMeditations';
import VideoPlayer from '../components/VideoPlayer'; 
import ZenSpinner from '../components/ZenSpinner';
import { Device } from '@capacitor/device';

const TemplePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const [isVideoOpen, setIsVideoOpen] = useState(false); // Gérer l'état d'ouverture de la vidéo
  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    const checkPlatform = async () => {
      const info = await Device.getInfo();
      if (info.platform === 'ios') {
        setIsIos(true);
      }
    };

    checkPlatform();
  }, []);

  const handleOpenVideo = () => {
    setIsVideoOpen(true);
  };

  const handleCloseVideo = () => {
    setIsVideoOpen(false);
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <Box>
      {isLoading ? (
        <ZenSpinner />
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }}>
        <Grid container spacing={1} justifyContent="center">
          
          {/* Ajout du bandeau fullwidth */}
          <Box sx={{ width: '100%', position: 'relative', overflow: 'hidden', mb: 3, height: isIos ? '22vh' : '18vh'}}>
            {/* Animation Lottie en arrière-plan */}
            <Box sx={{ position: 'absolute', width: '100%', height: '300px', top: 0, zIndex: -1, bgcolor:'secondary.main' }}>
              <Lottie
                loop
                animationData={pulseAnimation}
                play
                style={{ width: '100%', height: '100%' }}
              />
            </Box>
            <img src="/img/thibaut.svg" alt="Tibo" style={{ width: 130, height:130 , position:'absolute', bottom: 0, transform: 'translateX(-50%)', left: '50%' }} />
          </Box>

          {/* Texte et bouton */}
          <Box sx={{ mb:4, textAlign:'center' }}>
            <Typography variant='h5'>Commencer à méditer</Typography>
            <Typography variant='body2' >Quelques conseils de Thibaut</Typography>
            <Button
              variant="contained"
              sx={{ width: 'fit-content', mt: 1, height: '40px' }}
              startIcon={<PlayArrowRoundedIcon />}
              onClick={handleOpenVideo} // Ouvrir la vidéo en plein écran
            >
              Regarder
            </Button>
          </Box>

        {/* Intégration du lecteur vidéo */}
        <VideoPlayer
          videoUrl="/video/test.mp4" // URL de la vidéo à remplacer
          open={isVideoOpen}
          onClose={handleCloseVideo}
        />

          <Box sx={{px:3 }} >

          <TopMeditations />

          <Box sx={{ display:'flex', flexDirection: 'column', alignItems: 'center', pb: paddingBottom + 12 }} >

            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', mb:1.5}}>
              <Card elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '6px',  backgroundColor: theme.palette.secondary.main }}>
                <CardActionArea sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', pr:1 }} onClick={() => navigate('/meditation')}>
                  {isLoading ? (
                    <Skeleton variant="rectangular" height={150} animation="wave" width="100%" />
                  ) : (
                    <>
                      <CardContent sx={{ textAlign: 'left', flex: 1 }}>
                        <Typography gutterBottom variant="h6" component="div" sx={{ mb: 0 }}>
                          Méditations
                        </Typography>
                        <Typography gutterBottom variant="body2" sx={{ mb: 0 }}>
                          Une sélection parfaite pour s'accorder un moment.
                        </Typography>
                      </CardContent>
                      <CardMedia component="img" image="/img/cat-min.svg" alt="Techniques" sx={{ height: '120px', width: '120px', objectFit: 'cover', alignSelf: 'flex-end' }} />
                    </>
                  )}
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', mb:1.5 }}>
              <Card elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '6px', backgroundColor: theme.palette.secondary.main  }}>
                <CardActionArea sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', pr:1 }} onClick={() => navigate('/technique')}>
                  {isLoading ? (
                    <Skeleton variant="rectangular" height={150} animation="wave" width="100%" />
                  ) : (
                    <>
                      <CardContent sx={{ textAlign: 'left', flex: 1 }}>
                        <Typography gutterBottom variant="h6" component="div" sx={{ mb: 0 }}>
                          Techniques
                        </Typography>
                        <Typography gutterBottom variant="body2" sx={{ mb: 0 }}>
                          Méditations de base pour s'entrainer au quotidien.
                        </Typography>
                      </CardContent>
                      <CardMedia component="img" image="/img/fox-min.svg" alt="Techniques" sx={{ height: '120px', width: '120px', objectFit: 'cover', alignSelf: 'flex-end' }} />
                    </>
                  )}
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Card elevation={0} sx={{ width: '100%', display: 'flex', flexDirection: 'row', borderRadius: '6px', backgroundColor: theme.palette.secondary.main  }}>
                <CardActionArea sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', pr:1 }} onClick={() => navigate('/timer')}>
                  {isLoading ? (
                    <Skeleton variant="rectangular" height={150} animation="wave" width="100%" />
                  ) : (
                    <>
                      <CardContent sx={{ textAlign: 'left', flex: 1 }}>
                        <Typography gutterBottom variant="h6" component="div" sx={{ mb: 0 }}>
                          Chronomètre
                        </Typography>
                        <Typography gutterBottom variant="body2" sx={{ mb: 0 }}>
                          Sélectionne la durée et l'ambiance de ton choix.
                        </Typography>
                      </CardContent>
                      <CardMedia component="img" image="/img/monkey-min.svg" alt="Chronomètre" sx={{ height: '120px', width: '120px', objectFit: 'cover', alignSelf: 'flex-end' }} />
                    </>
                  )}
                </CardActionArea>
              </Card>
            </Grid>
          </Box>

          </Box>
          </Grid>
      </Box>
    )}
  </Box>
);
};

export default TemplePage;
