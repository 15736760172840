import { useEffect, useState } from 'react';
import { Device } from '@capacitor/device';

const useDeviceInfo = () => {
  const [isIos, setIsIos] = useState(false);
  const [paddingTop, setPaddingTop] = useState(0);
  const [paddingBottom, setPaddingBottom] = useState(0);

  useEffect(() => {
    const checkDevice = async () => {
      const deviceInfo = await Device.getInfo();
      console.log('Device Info:', deviceInfo);

      // Groupes de modèles d'iPhone selon la taille de l'encoche/Dynamic Island
      const smallNotchModels = [
        'iPhone13,1', 'iPhone13,2', 'iPhone13,3', 'iPhone13,4', // iPhone 12 et 13, petite encoche
        'iPhone14,5', 'iPhone14,6' // iPhone 14 (non Pro)
      ];

      const classicNotchModels = [
        'iPhone10,3', 'iPhone10,6', // iPhone X
        'iPhone11,2', 'iPhone11,4', 'iPhone11,6', 'iPhone11,8', // iPhone XR, XS, XS Max
        'iPhone12,1', 'iPhone12,3', 'iPhone12,5' // iPhone 11, 11 Pro
      ];

      const dynamicIslandModels = [
        'iPhone15,2', 'iPhone15,3', 'iPhone15,4', 'iPhone15,5', // iPhone 14 Pro, Pro Max
        'iPhone16,1', 'iPhone16,2' // iPhone 15 Pro, Pro Max
      ];

      if (deviceInfo.platform === 'ios') {
        setIsIos(true);
        if (smallNotchModels.includes(deviceInfo.model)) {
          setPaddingTop(2); // Petite encoche
          setPaddingBottom(0); // Padding en bas
        } else if (classicNotchModels.includes(deviceInfo.model)) {
          setPaddingTop(4); // Encoche classique
          setPaddingBottom(2); // Padding en bas
        } else if (dynamicIslandModels.includes(deviceInfo.model)) {
          setPaddingTop(6); // Dynamic Island
          setPaddingBottom(2); // Padding en bas
        } else {
          setPaddingTop(1); // iPhone sans encoche
          setPaddingBottom(0); // Pas de padding en bas
        }
      } else {
        setIsIos(false);
        setPaddingTop(0); // Padding générique pour Android
        setPaddingBottom(0);
      }
    };

    checkDevice();
  }, []);

  return { isIos, paddingTop, paddingBottom };
};

export default useDeviceInfo;
