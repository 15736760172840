// src/components/onboarding/FinalSlide.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FinalSlide = () => {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/today');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center', padding: 3 }}>
      <Typography variant="h4" sx={{ mb: 1 }}>
        Prêt à méditer ?
      </Typography>
      <Button variant="contained" color="primary" sx={{ width:'60%' }} onClick={handleStart}>
        C'est parti 
      </Button>
    </Box>
  );
};

export default FinalSlide;
