import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie-player';
import animationData from '../../assets/pulse.json';
import { Box, Typography, Fade } from '@mui/material';

const WelcomeSlide = () => {
  const [currentText, setCurrentText] = useState('');
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const textSequence = [
      { text: 'Inspirez', duration: 5000 },  // Prolonger à 5 secondes
      { text: 'Expirez', duration: 5000 }    // Prolonger à 5 secondes
    ];
    let index = 0;

    const interval = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentText(textSequence[index].text);
        setFadeIn(true);
        index += 1;
        if (index === textSequence.length) {
          clearInterval(interval);
        }
      }, 1000); // 1 second fade out
    }, textSequence[index]?.duration || 3000); // Dynamic duration

    setTimeout(() => {
      setCurrentText(textSequence[index].text);
      setFadeIn(true);
      index += 1;
    }, 2000); // Initial delay for "Inspirez" (2 seconds to ensure the page loads)

    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw', bgcolor: 'secondary.main', color: 'black', textAlign: 'center', position: 'relative', overflow: 'hidden' }}>
      <Box
        sx={{
          position: 'absolute',
          width: '1100px',
          height: '1100px',
          opacity: 1, // Always visible
          transition: 'opacity 0.5s ease-in-out',
        }}
      >
        <Lottie
          loop
          animationData={animationData}
          play
          speed={0.4}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Fade in={fadeIn} timeout={{ enter: 1000, exit: 1000 }}>
          <Typography variant="h3">
            {currentText}
          </Typography>
        </Fade>
      </Box>
    </Box>
  );
};

export default WelcomeSlide;
