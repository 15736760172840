// src/components/ads/MeditateWithFriend.js
import React from 'react';
import { Container, Typography, Box, Avatar, Button, TextField, IconButton } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import ChatIcon from '@mui/icons-material/Chat';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useNavigate } from 'react-router-dom';
import useDeviceInfo from '../../utils/useDeviceInfo';
import SelfImprovementRounded from '@mui/icons-material/SelfImprovementRounded';

const MeditateWithFriend = () => {
  const navigate = useNavigate();
  const { paddingTop, paddingBottom } = useDeviceInfo();

  return (
    <Container sx={{ textAlign: 'center', px: 3, mt: 3 }}>
      <Box sx={{ mb: 3, bgcolor:'secondary.main', borderRadius:'8px', p:2 }}>
        <Typography variant="h5" sx={{ mb: 0.5 }} gutterBottom>Méditer avec des amis</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <LockIcon fontSize='15' sx={{ mr: 1 }} />
          <Typography fontWeight="bold" textTransform="uppercase" variant="body2">Réservé aux abonnés</Typography>
        </Box>
      </Box>
      <Box sx={{ width: 'fit-content', margin: '0 auto', mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Avatar sx={{ bgcolor: 'primary.main', mr: 1, width: 24, height: 24 }}>
            <PeopleIcon sx={{ width: 16, height: 16 }} />
          </Avatar>
          <Typography fontWeight="bold" variant="body1">
            Médite en direct avec tes amis
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Avatar sx={{ bgcolor: 'primary.main', mr: 1, width: 24, height: 24 }}>
            <ChatIcon sx={{ width: 16, height: 16 }} />
          </Avatar>
          <Typography fontWeight="bold" variant="body1">
            Créé des cercles à plusieurs
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar sx={{ bgcolor: 'primary.main', mr: 1, width: 24, height: 24 }}>
            <CalendarTodayIcon sx={{ width: 16, height: 16 }} />
          </Avatar>
          <Typography fontWeight="bold" variant="body1">
            Planifie des notifications
          </Typography>
        </Box>
      </Box>
      <Button variant="contained" color="primary" onClick={() => navigate('/checkout')}>Essayer gratuitement</Button>
      <Typography variant="body2" sx={{ mt: 1 }}>14 jours d'essais - Résiliable en 3 clics</Typography>
    
      <Box sx={{ position:'fixed', bottom:'-1px', left:0, width: '100%', backgroundColor:'white', pb: paddingBottom + 2, pt:1.5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mx:2, backgroundColor: '#eee', borderRadius: '20px', padding: '4px', pl: '10px'}}>
          <TextField
            variant="outlined"
            placeholder="Message"
            value="Message"
            disabled // Ajout du disabled ici pour désactiver l'input
            sx={{
              flexGrow: 1,
              '& .MuiOutlinedInput-root': {
                borderRadius: '20px',
                backgroundColor: '#eee',
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
              },
              '& .MuiOutlinedInput-input': {
                padding: '10px 12px',
              },
            }}
          />
          <IconButton
            sx={{
              marginLeft: '8px',
              color: 'black',
              backgroundColor: '#EEEEEE',
              '&:hover': {
                backgroundColor: '#DDDDDD',
              },
            }}
            disabled // Ajout du disabled ici pour désactiver le premier bouton
          >
          </IconButton>
          <IconButton
            sx={{
              marginLeft: '4px',
              color: 'white',
              backgroundColor: '#000',
              '&:hover': {
                backgroundColor: '#333',
              },
            }}
            disabled // Ajout du disabled ici pour désactiver le second bouton
          >
            <SelfImprovementRounded />
          </IconButton>
        </Box>
      </Box>

    
    </Container>
  );
};

export default MeditateWithFriend;
