import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Paper, Button, Avatar, Fade, AppBar, Toolbar, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useDeviceInfo from '../utils/useDeviceInfo';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const onboardingSteps = [
  {
    sender: "Thibaut",
    message: "Bienvenue ! Je suis Thibaut, et je vais t'accompagner pour configurer ton espace de méditation.",
    responses: ["C'est parti !"]
  },
  {
    sender: "Thibaut",
    message: "La méditation a plein de bienfaits : elle réduit le stress, améliore la concentration, et favorise le sommeil.",
    responses: ["Je ne savais pas, impressionnant !"]
  },
  {
    sender: "Thibaut",
    message: "Pour bien commencer, je te conseille d’aller apprendre les 4 techniques de base. Tu les trouveras dans l'onglet tout à droite.",
    responses: ["D'accord"]
  },
  {
    sender: "Thibaut",
    message: "À quel moment préfères-tu méditer ?",
    responses: ["Le matin", "Le midi", "Le soir"]
  },
  {
    sender: "Thibaut",
    message: "Ok ! Active les notifications pour recevoir des rappels de méditation et pouvoir discuter avec tes amis.",
    responses: ["J'active les notifications"]
  },
  {
    sender: "Thibaut",
    message: "Méditer, c'est encore mieux avec des amis ! Ajouter des amis sur l’app te permettra de partager tes progrès et de rester motivé.",
    responses: ["J'ajoute des amis maintenant."]
  },
  {
    sender: "Thibaut",
    message: "Tu n’as pas encore d’amis ici ? Pas de souci ! Invite-en 5 à te rejoindre, et vous pourrez méditer ensemble.",
    responses: ["OK, j'invite mes amis."]
  },
  {
    sender: "Thibaut",
    message: "Tout est prêt ! N'hésite pas à me contacter si tu as des questions. Bonne méditation !",
    responses: ["Merci, à toi aussi !"]
  }
];

const OnboardingChat = () => {
  const [step, setStep] = useState(0);
  const [conversation, setConversation] = useState([{ sender: "Thibaut", message: onboardingSteps[0].message }]); // Initialise avec le premier message de Thibaut
  const [isTyping, setIsTyping] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false); // État pour désactiver les boutons après clic
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const { paddingTop, paddingBottom } = useDeviceInfo();

  // Fonction pour calculer le temps de "taping" en fonction de la longueur du message
  const calculateTypingDelay = (message) => {
    const wordsCount = message.split(" ").length;
    return Math.min(3000, wordsCount * 200); // Limite à 3 secondes max
  };

  // Fonction pour faire défiler automatiquement vers le bas
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleEndClick = () => {
    navigate('/');
  };

  // Défilement automatique après chaque nouveau message
  useEffect(() => {
    scrollToBottom();
  }, [conversation, isTyping]);

  const handleNextStep = (response) => {
    // Désactiver les boutons immédiatement après un clic
    setButtonDisabled(true);
    
    // Ajoute la réponse de l'utilisateur
    setConversation((prev) => [...prev, { sender: "User", message: response }]);
    setIsTyping(true); // Démarre l'animation "en cours de réponse"

    // Simule un délai avant la réponse de Thibaut
    const nextStep = onboardingSteps[step + 1];
    const typingDelay = calculateTypingDelay(nextStep?.message || "");

    setTimeout(() => {
      setIsTyping(false); // Arrête l'animation
      if (nextStep) {
        setConversation((prev) => [...prev, nextStep]); // Ajoute la réponse de Thibaut
        setStep(step + 1);
      }
      setButtonDisabled(false); // Réactiver les boutons après la réponse
    }, typingDelay); // Délai ajusté selon la longueur du message
  };

  return (
    <>
      <AppBar position="static" sx={{ border: '0px', boxShadow:'none', background:'#FFF' }}>
        <Toolbar sx={{ pt: paddingTop + 2, px: 2, justifyContent: 'flex-end' }}>
          <IconButton
            size="small"
            color="black"
            sx={{
              p: 0.5,
              backgroundColor: 'white',
              borderRadius: '50%',
              border: '1px solid #DDD',
            }}
            aria-label="retour"
            onClick={handleEndClick}
          >
            <CloseRoundedIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', px: 3, mt: 3 }}>
        
        <Box sx={{ width: '100%', maxWidth: 400, overflowY: 'auto', mb: 2, pb: 8 }}>
          {conversation.map((conv, index) => (
            <Fade in={true} key={index} timeout={{ enter: 800 }}>
              <Box sx={{ display: 'flex', mb: 2, justifyContent: conv.sender === "User" ? 'flex-end' : 'flex-start' }}>
                {conv.sender === "Thibaut" && (
                  <Avatar src="/img/thibaut.svg" sx={{ width: 44, height: 44, mr: 1.5, border: '1px solid #DDD' }} />
                )}
                <Paper elevation="0" sx={{ px: 2, py: 1.5, backgroundColor: conv.sender === "User" ? 'primary.main' : '#FFF', color: conv.sender === "User" ? '#FFF' : '#000', borderRadius: 4, border: conv.sender === "User" ? '1px solid #000' : '1px solid #DDD', }}>
                  <Typography variant="body2">{conv.message}</Typography>
                </Paper>
              </Box>
            </Fade>
          ))}

          {isTyping && (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar src="/img/thibaut.svg" sx={{ width: 44, height: 44, mr: 1.5, border: '1px solid #DDD' }} />
              <Typography variant="body2" sx={{ backgroundColor: '#FFF', borderRadius: '20px', px: 0, py: 1 }}>
                <span className="dot one">•</span><span className="dot two">•</span><span className="dot three">•</span>
              </Typography>
            </Box>
          )}

          <div ref={messagesEndRef} />
        </Box>

        {step < onboardingSteps.length - 1 && (
          <Box sx={{ position: 'fixed', bottom: '-1px', width: '100%', backgroundColor: 'white', border: '0px', pb: paddingBottom + 2, pt: 1.5, px: 3 }}>
            {/* Si les réponses sont sur une ligne */}
            {onboardingSteps[step].responses.length === 3 && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {onboardingSteps[step].responses.map((response, idx) => (
                  <Button
                    key={idx}
                    sx={{ width: 'fit-content', mx: 0.5 }}
                    variant="contained"
                    onClick={() => handleNextStep(response)}
                    disabled={buttonDisabled} // Désactiver les boutons si déjà cliqués
                  >
                    {response}
                  </Button>
                ))}
              </Box>
            )}
            {/* Si une seule réponse */}
            {onboardingSteps[step].responses.length === 1 && (
              <Button
                variant="contained"
                onClick={() => handleNextStep(onboardingSteps[step].responses[0])}
                disabled={buttonDisabled} // Désactiver les boutons si déjà cliqués
              >
                {onboardingSteps[step].responses[0]}
              </Button>
            )}
          </Box>
        )}

        <Box sx={{ position: 'fixed', bottom: '-1px', width: '100%', pb: paddingBottom + 2, pt: 1.5, px: 3 }}>
          {/* Bouton "Terminer" à la fin */}
          {step === onboardingSteps.length - 1 && (
            <Button variant="contained" onClick={() => navigate('/')}>
              Terminer
            </Button>
          )}
        </Box>

        <React.Fragment>
          <style>
            {`
              .dot {
                animation: blink 1.5s infinite;
              }
              .dot.one {
                animation-delay: 0s;
              }
              .dot.two {
                animation-delay: 0.3s;
              }
              .dot.three {
                animation-delay: 0.6s;
              }

              @keyframes blink {
                0% { opacity: 0; }
                50% { opacity: 1; }
                100% { opacity: 0; }
              }
            `}
          </style>
        </React.Fragment>
      </Box>
    </>
  );
};

export default OnboardingChat;
