import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Box, CircularProgress } from '@mui/material';

const StatusPage = () => {
  const navigate = useNavigate();
  const { currentUser, refreshUser, refreshToken } = useAuth();

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      await refreshToken(); 
      await refreshUser(); 
      
      if (currentUser.subscriptionStatus === 'active') {
        navigate('/success');
      } else {
        navigate('/checkout'); 
      }
    };

    checkSubscriptionStatus();
  }, [navigate, refreshUser, refreshToken, currentUser]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center' }}>
      <CircularProgress />
    </Box>
  );
};

export default StatusPage;
