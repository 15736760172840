import React from 'react';
import { Box, List, ListItem, Avatar, Typography, Paper } from '@mui/material';
import AutoDeleteRoundedIcon from '@mui/icons-material/AutoDeleteRounded';

const MessagesList = ({ messages, currentUser, connectedUsers, inviteMessageRef, messagesEndRef, roomCode, isInviteCardVisible }) => {
  const getSenderName = (userId) => {
    return connectedUsers[userId]?.name || 'Unknown';
  };

  const getSenderAvatar = (userId) => {
    return connectedUsers[userId]?.profileImage ? `/${connectedUsers[userId].profileImage}` : '/avatar/default.jpg';
  };

  return (
    <>
      {/* Bloc sorti du composant overflowY */}
      {!isInviteCardVisible && messages.length === 0 && (
        <Box sx={{ textAlign:'center', px:10, margin:'auto auto', mt:32}}>
          <AutoDeleteRoundedIcon sx={{ fontSize: 20, color: 'black'}}/>
          <Typography variant='body2' sx={{ mb:0.5, fontWeight:'bold' }}>
            Conversation éphémère
          </Typography>
          <Typography variant='body2' sx={{ fontSize:'0.8em' }}>
            Chaque jour, vos messages sont effacés à minuit.
          </Typography>
        </Box>
      )}

      <Box sx={{ pb: 9, px: 2 }}>
        <List sx={{ padding: 0 }}>
          {messages.map((msg, index) => {
            const isCurrentUser = msg.sender === currentUser.userId;
            const showAvatarAndName = index === 0 || messages[index - 1].sender !== msg.sender;

            let parsedMessage;
            try {
              parsedMessage = JSON.parse(msg.message);
            } catch (error) {
              parsedMessage = { text: msg.message };
            }

            return (
              <React.Fragment key={index}>
                {!isCurrentUser && showAvatarAndName && (
                  <Typography variant="subtitle2" color="textSecondary" sx={{ alignSelf: 'flex-start', margin: '10px 0 0 50px', mb:'5px', fontSize:'0.8em'  }}>
                    {getSenderName(msg.sender)}
                  </Typography>
                )}
                <ListItem
                  sx={{
                    display: 'flex',
                    flexDirection: isCurrentUser ? 'row-reverse' : 'row',
                    alignItems: 'flex-start',
                    padding: '0px 0',
                    paddingLeft: showAvatarAndName ? '0' : '40px',
                  }}
                >
                  {!isCurrentUser && showAvatarAndName && (
                    <Avatar
                      src={getSenderAvatar(msg.sender)}
                      sx={{ width: 32, height: 32, margin: '0 8px 0 0', border:'1px solid #DDD' }}
                    />
                  )}
                  <Paper
                    sx={{
                      boxShadow: 'none',
                      backgroundColor: isCurrentUser ? 'primary.main' : '#EEE',
                      color: isCurrentUser ? '#FFF' : '#000',
                      borderRadius: '20px',
                      padding: '8px 16px',
                      position: 'relative',
                      marginBottom: '5px',
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                      <Typography variant="body2" color="inherit" component="span" sx={{ lineHeight: '1.4em'}}>
                        {parsedMessage.text || msg.message}
                      </Typography>
                      <Typography sx={{ fontSize: '0.65rem', color: isCurrentUser ? '#FFF' : '#000', marginLeft: '8px' }}>
                        {new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </Typography>
                    </Box>
                  </Paper>
                </ListItem>
              </React.Fragment>
            );
          })}
          <div ref={messagesEndRef} />
        </List>
      </Box>
    </>
  );
};

export default MessagesList;
