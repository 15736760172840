import React, { useState, useEffect } from 'react';
import { Box, TextField, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, Button, Drawer } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import de useNavigate
import Reply from '@mui/icons-material/Send';
import SelfImprovementRounded from '@mui/icons-material/SelfImprovementRounded';
import MeditationSettings from './MeditationSettings';
import { v4 as uuidv4 } from 'uuid';
import useDeviceInfo from '../../utils/useDeviceInfo';

const MessageInput = ({ roomCode, socket, messages, currentUser, inviteMessageRef }) => {
  const [message, setMessage] = useState('');
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentSettings, setCurrentSettings] = useState(null);
  const [meditationState, setMeditationState] = useState('completed'); // Default to 'completed'
  const [existingInvite, setExistingInvite] = useState(null); // Stocke l'invitation existante
  const { paddingTop, paddingBottom } = useDeviceInfo();

  const navigate = useNavigate(); // Initialisation de useNavigate

  useEffect(() => {
    if (socket) {
      // Appeler le serveur pour obtenir l'état actuel de la méditation
      socket.emit('getMeditationState', { room: roomCode });
  
      // Écouter la réponse du serveur
      socket.on('meditationStateResponse', (data) => {
        console.log('État de la méditation récupéré depuis le serveur:', data.state);
        setMeditationState(data.state); // Mettre à jour l'état local avec celui récupéré
      });
  
      socket.on('updateMeditationState', (state) => {
        console.log('État reçu du serveur via socket:', state); // Log pour vérifier l'état reçu
        if (['pending', 'progress', 'completed'].includes(state)) {
          setMeditationState(state); // Met à jour l'état localement
        }
      });
  
      // Écouter les messages reçus
      socket.on('receiveMessage', (msg) => {
        let parsedMessage;
  
        // Vérification et tentative de parsing du JSON
        try {
          parsedMessage = JSON.parse(msg.message);
        } catch (error) {
          parsedMessage = { text: msg.message };  // Si ce n'est pas du JSON, on considère que c'est du texte simple
        }
  
        if (parsedMessage.isInvite) {
          setExistingInvite({ ...msg, ...parsedMessage }); // Stocker l'invitation si c'est un message d'invitation
        }
      });
    }
  
    return () => {
      if (socket) {
        socket.off('updateMeditationState');
        socket.off('receiveMessage');
      }
    };
  }, [socket, roomCode]);
  

  const handleMeditateClick = () => {
    if (meditationState === 'pending') {
      setShowInviteModal(true);
    } else if (meditationState === 'progress') {
      setShowInviteModal(true);
    } else if (meditationState === 'completed') {
      setIsDrawerOpen(true);
    }
  };

  const handleModifyInvite = () => {
    if (existingInvite) {
      setCurrentSettings({
        meditationType: existingInvite.meditationType,
        soundBackground: existingInvite.soundBackground,
        duration: existingInvite.duration,
      });
    }
    setIsDrawerOpen(true);
    setShowInviteModal(false);
  };

  const handleSubmitSettings = (settings) => {
  
    if (settings.meditationType && settings.soundBackground !== undefined && settings.duration) {
      if (socket) {
        const inviteId = uuidv4();  // Générer un inviteId unique ici
        socket.emit('startMeditation', {
          room: roomCode,
          meditationType: settings.meditationType,
          soundBackground: settings.soundBackground,
          duration: settings.duration,
          inviteId,  // Envoyer inviteId avec l'événement
        });
  
        // On n'envoie plus l'invitation comme un message dans le chat
      }
    } else {
    }
  
    setIsDrawerOpen(false);
  };
  

  const sendMessage = () => {
    if (message && socket) {
      socket.emit('sendMessage', { room: roomCode, message, sender: currentUser.userId });
      setMessage('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };


  return (
    <Box sx={{ position:'fixed', bottom:'-1px', width: '100%', backgroundColor:'white', pb: paddingBottom + 2, pt:1.5 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mx:2, backgroundColor: '#eee', borderRadius: '20px', padding: '4px', pl: '10px'}}>
        <TextField
          variant="outlined"
          placeholder="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          sx={{
            flexGrow: 1,
            '& .MuiOutlinedInput-root': {
              borderRadius: '20px',
              backgroundColor: '#eee',
              '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                border: 'none',
              },
            },
            '& .MuiOutlinedInput-input': {
              padding: '10px 12px',
            },
          }}
        />
        <IconButton
          onClick={sendMessage}
          disabled={!message}
          sx={{
            marginLeft: '8px',
            color: 'black',
            backgroundColor: '#EEEEEE',
            '&:hover': {
              backgroundColor: '#DDDDDD',
            },
          }}
        >
          <Reply sx={{ fontSize: '20px' }} />
        </IconButton>
        <IconButton
          onClick={handleMeditateClick}
          sx={{
            marginLeft: '4px',
            color: 'white',
            backgroundColor: '#000',
            '&:hover': {
              backgroundColor: '#333',
            },
          }}
        >
          <SelfImprovementRounded />
        </IconButton>
      </Box>

      <Dialog
        open={showInviteModal}
        onClose={() => setShowInviteModal(false)}
      >
        <DialogContent sx={{ px:3, pt:3, pb:0}}>
          <DialogContentText fontWeight='bold' color='black'>
            {meditationState === 'pending' && "Il y a déjà une invitation en cours"}
            {meditationState === 'progress' && "Il y a déjà une méditation en cours"}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pb:3, px:3}}>
          {meditationState === 'pending' && (
            <>
              <Button onClick={handleModifyInvite} variant="contained"  color="primary">
                Modifier
              </Button>
            </>
          )}
            {meditationState === 'progress' && (
            <>
                <Button 
                onClick={() => navigate(`/room/${roomCode}/live`)} // Navigation vers la page live
                variant="contained" 
                color="primary"
                >
                Rejoindre
                </Button>
                <Button 
                onClick={() => {
                    if (socket) {
                    socket.emit('endMeditation', { room: roomCode }); // Émettre l'événement pour terminer la méditation
                    setMeditationState('completed'); // Mettre à jour l'état localement
                    setShowInviteModal(false); // Fermer la fenêtre modale
                    }
                }} 
                color="error"
                sx={{ ml: 2 }} // Ajout d'un espace entre les boutons
                >
                Réinitialiser
                </Button>
            </>
            )}
        </DialogActions>
      </Dialog>

      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <MeditationSettings 
          onSubmit={handleSubmitSettings} 
          initialSettings={currentSettings} // Passer les settings actuels
          roomCode={roomCode}  // Assurez-vous que roomCode est bien passé ici
        />
      </Drawer>
    </Box>
  );
};

export default MessageInput;