import React, { useState, useEffect } from 'react';
import { Box, Alert, Collapse, List, ListItem, ListItemText, Avatar, ListItemAvatar, IconButton, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Plugins } from '@capacitor/core';
const { Contacts, Device } = Plugins;
import { useAuth } from '../../../contexts/AuthContext';

const AddContact = () => {
  const { currentUser } = useAuth();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [friends, setFriends] = useState([]);
  const [pendingFriends, setPendingFriends] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
        setSuccess('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.friends) {
        setFriends(currentUser.friends);
      }
      if (currentUser.pendingFriends) {
        setPendingFriends(currentUser.pendingFriends);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const storedContacts = localStorage.getItem('contacts');
    if (storedContacts) {
      setContacts(JSON.parse(storedContacts));
      setLoading(false);
    } else {
      handleRequestPermissions();
    }
  }, []);

  const handleRequestPermissions = async () => {
    try {
      const info = await Device.getInfo();
      if (info.platform === 'ios') {
        const permission = await Contacts.requestPermissions();
        if (permission.contacts === 'granted') {
          fetchContacts();
        } else {
          setPermissionDenied(true);
          setError('Autorise l\'application à accéder à tes contacts pour trouver tes amis');
          setLoading(false);
        }
      } else if (info.platform === 'android') {
        const permission = await Contacts.requestPermissions();
        if (permission.contacts === 'granted') {
          fetchContacts();
        } else {
          setPermissionDenied(true);
          setError('Autorise l\'application à accéder à tes contacts pour trouver tes amis');
          setLoading(false);
        }
      } else {
        setError('Plateforme non supportée');
        setShowAlert(true);
        setLoading(false);
      }
    } catch (error) {
      setError('Erreur lors de la demande de permissions.');
      setShowAlert(true);
      setLoading(false);
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    let formatted = phoneNumber.replace(/\s+/g, '').replace(/[-()]/g, '');

    if (formatted.startsWith('+33')) {
      formatted = formatted.replace('+33', '0');
    }

    return formatted;
  };

  const fetchContacts = () => {
    try {
      navigator.contacts.find(
        ['displayName', 'phoneNumbers'],
        (contacts) => {
          const phoneNumbers = contacts
            .filter(contact => contact.phoneNumbers && contact.phoneNumbers.length > 0)
            .map(contact => formatPhoneNumber(contact.phoneNumbers[0].value));

          checkActiveContacts(phoneNumbers, contacts);
        },
        (error) => {
          setError('Erreur lors de la récupération des contacts.');
          setShowAlert(true);
          setLoading(false);
        }
      );
    } catch (error) {
      setError('Erreur lors de la récupération des contacts.');
      setShowAlert(true);
      setLoading(false);
    }
  };

  const checkActiveContacts = async (phoneNumbers, contacts) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/check-contacts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ phoneNumbers })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch active contacts');
      }

      const activeContacts = await response.json();
      let mergedContacts = activeContacts.map(activeContact => {
        const originalContact = contacts.find(contact => 
          contact.phoneNumbers && contact.phoneNumbers[0] && formatPhoneNumber(contact.phoneNumbers[0].value) === activeContact.phone
        );
        return {
          ...activeContact,
          displayName: originalContact ? `${activeContact.firstName || ''} ${activeContact.lastName || ''}`.trim() : 'Nom non disponible'
        };
      });

      mergedContacts = mergedContacts.filter(contact => 
        !friends.some(friend => friend.phone === contact.phone) &&
        !pendingFriends.some(pending => pending.phone === contact.phone)
      );

      localStorage.setItem('contacts', JSON.stringify(mergedContacts));
      
      setContacts(mergedContacts);
      setSuccess('Contacts récupérés avec succès');
      setShowAlert(true);
      setLoading(false);
    } catch (error) {
      setError(`Erreur lors de la vérification des contacts actifs: ${error.message}`);
      setShowAlert(true);
      setLoading(false);
    }
  };

  const handleAddFriend = async (username) => {
    if (friends.some(friend => friend.username === username) || pendingFriends.some(pending => pending.username === username)) {
      setError('Demande d\'ami déjà envoyée ou vous êtes déjà amis.');
      setShowAlert(true);
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/friend/invite/${username}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to send friend request');
      }
  
      setSuccess(`Demande d'ami envoyée à ${username}`);
      setShowAlert(true);
    } catch (error) {
      setError(`Erreur lors de l'envoi de la demande d'ami: ${error.message}`);
      setShowAlert(true);
    }
  };

  return (
    <Box>
      <Box sx={{ mt: 2 }}>
        <Collapse in={showAlert || permissionDenied}>
          {error && <Alert severity="error" onClose={() => setPermissionDenied(false)}>{error}</Alert>}
          {success && <Alert severity="success" onClose={() => setShowAlert(false)}>{success}</Alert>}
        </Collapse>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="20vh">
          <CircularProgress />
        </Box>
      ) : (
        <List sx={{ p: 0 }}>
          {contacts.map((contact, index) => (
            <ListItem sx={{ p: 0 }} key={index}>
              <ListItemAvatar>
                <Avatar sx={{ border: '1px solid #ddd' }} alt={contact.username} src={contact.avatar} />
              </ListItemAvatar>
              <ListItemText
                primary={`${contact.firstName || ''} ${contact.lastName || ''}`.trim()}
                secondary={contact.username || 'Nom d\'utilisateur non disponible'}
              />
              <IconButton
                sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'black' } }}
                onClick={() => handleAddFriend(contact.username)}
              >
                <AddIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default AddContact;
