import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Device } from '@capacitor/device';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { App } from '@capacitor/app';
import { Container, Avatar, Button, Typography, CircularProgress, Box, Link, Drawer, IconButton, AppBar, Toolbar } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Group, PlayCircleOutline, FavoriteRounded } from '@mui/icons-material';
import { useAuth } from '../../contexts/AuthContext';
import logo from '../../assets/logo.svg';
import PrivacyPolicyPage from '../PrivacyPolicyPage';
import useDeviceInfo from '../../utils/useDeviceInfo';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

const entitlementIdentifier = process.env.REACT_APP_ENTITLEMENT_IDENTIFIER;

const CheckoutPage = () => {
  const [products, setProducts] = useState([]);
  const [purchasing, setPurchasing] = useState(false);
  const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [isPurchaseActive, setIsPurchaseActive] = useState(false); // Nouvel état pour suivre l'état de l'achat
  const navigate = useNavigate();
  const { currentUser, refreshUser } = useAuth();
  const { paddingTop, paddingBottom } = useDeviceInfo();

  const handleBackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    const configurePurchases = async () => {
      if (!currentUser) {
        console.error('Current user is not defined');
        return;
      }

      try {
        const info = await Device.getInfo();
        const apiKey = info.platform === 'ios'
          ? process.env.REACT_APP_REVENUECAT_IOS_API_KEY
          : process.env.REACT_APP_REVENUECAT_ANDROID_API_KEY;

        if (!apiKey) {
          console.error('API key is missing for platform:', info.platform);
          return;
        }

        const appUserID = currentUser.userId;

        await Purchases.configure({ apiKey, appUserID });

        const offerings = await Purchases.getOfferings();
        setProducts(offerings.current.availablePackages);
      } catch (error) {
        console.error('Error configuring purchases:', error);
      }
    };

    configurePurchases();

    const handleAppStateChange = (state) => {
      console.log('App state change:', state);
      if (state.isActive && isPurchaseActive) {
        setIsPurchaseActive(false);
        navigate('/status');
      }
    };

    App.addListener('appStateChange', handleAppStateChange);

    return () => {
      App.removeListener('appStateChange', handleAppStateChange);
    };
  }, [currentUser, navigate, refreshUser, isPurchaseActive]);

  const handlePurchase = async (product) => {
    setPurchasing(true);
    try {
      setIsPurchaseActive(true); // Indiquer que l'achat est en cours
      const purchaseResult = await Purchases.purchasePackage({ aPackage: product });
      if (purchaseResult.customerInfo.entitlements.active[entitlementIdentifier]) {
        console.log('Achat réussi');
        await refreshUser();
        if (currentUser.subscriptionStatus === 'active') {
          navigate('/status');
        }
      }
    } catch (error) {
      if (error.code === Purchases.PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
        console.log('Achat annulé');
      } else {
        console.error('Erreur lors de l\'achat:', error);
      }
    } finally {
      setPurchasing(false);
      setIsPurchaseActive(false); // Réinitialiser l'état de l'achat
    }
  };

  const handlePrivacyPolicyOpen = () => {
    setPrivacyPolicyOpen(true);
  };

  const handlePrivacyPolicyClose = () => {
    setPrivacyPolicyOpen(false);
  };

  return (
    <>
      {/* Toolbar avec bouton de retour */}
      <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Toolbar sx={{ pt: paddingTop + 2, px: 2 }}>
          <IconButton
            size="small"
            sx={{
              p: 0.5,
              border: '1px solid #ddd',
              borderRadius: '50%',
              mr: 2,
            }}
            aria-label="retour"
            onClick={handleBackClick}
          >
            <ArrowBackIosRoundedIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '94vh',
        textAlign: 'center',
        position: 'relative',
      }}
    >
      <Box sx={{ mb: 3 }}>
        <img src={logo} alt="Logo" style={{ height: '50px', display: 'block', margin: '0 auto' }} />
      </Box>
      <Box>
        {!currentUser.subscriptionStartDate ? (
          <>
            <Box sx={{ mb: 3}} >
              <Typography variant="h5" sx={{ mb: 1.5, mt: 0 }} gutterBottom>
                Abonnement classique
              </Typography>
              <Typography variant="body" sx={{backgroundColor:'secondary.main', fontWeight:'bold', px:2, py:1, borderRadius:'8px'}}>
                2.99€ tous les mois
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'left', mb: 3, px: 3 }}>
              <Box sx={{ width: 'fit-content', margin:'0 auto' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Avatar sx={{ bgcolor: 'primary.main', mr: 1, width: 24, height: 24  }}>
                    <Group sx={{ width: 16, height: 16 }} />
                  </Avatar>
                  <Typography variant="body2">
                    Médite en direct avec tes amis
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Avatar sx={{ bgcolor: 'primary.main', mr: 1, width: 24, height: 24  }}>
                    <PlayCircleOutline sx={{ width: 16, height: 16 }} />
                  </Avatar>
                  <Typography variant="body2">
                    Apprends les bases de la méditation
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ bgcolor: 'primary.main', mr: 1,  width: 24, height: 24  }}>
                    <FavoriteRounded sx={{ width: 16, height: 16 }} />
                  </Avatar>
                  <Typography variant="body2">
                    Accède à un groupe privé d'entraide
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="contained"
                sx={{ mt: 2.5 }}
                color="primary"
                fullWidth
                onClick={() => handlePurchase(products[0])}
                disabled={purchasing}
              >
                {purchasing ? <CircularProgress size={24} /> : 'Commencer mon essai gratuit'}
              </Button>
              <Box sx={{ mt: 1.5, textAlign:'center', display:'flex', flexDirection:'row', justifyContent:'center' }}>
                <Link
                  href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                  target="_blank"
                  rel="noopener"
                  sx={{ fontSize: '12px', fontWeight: 'bold', textDecoration: 'underline', px:0.5}}
                >
                  Conditions d'utilisation
                </Link>
              </Box>
              <Typography variant="body2" sx={{ mt: 2, textAlign: 'center', fontStyle:'italic' }}>
                Renouvelé automatiquement tous les mois, résiliable à tout moment.
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h6" sx={{ mb: 2, mt: 0 }} gutterBottom>
              Vous nous manquez !
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handlePurchase(products[0])}
              disabled={purchasing}
            >
              {purchasing ? <CircularProgress size={24} /> : 'Renouveler l\'abonnement'}
            </Button>
            <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
              Renouvelé automatiquement tous les mois, résiliable à tout moment.
            </Typography>
          </>
        )}
      </Box>
      <Drawer
        anchor="bottom"
        open={isPrivacyPolicyOpen}
        onClose={handlePrivacyPolicyClose}
      >
        <Box sx={{ width: '100%', p: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', height: '50px' }}>
            <IconButton onClick={handlePrivacyPolicyClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ px: 3}}>
            <PrivacyPolicyPage />
          </Box>
        </Box>
      </Drawer>
    </Container>
    </>
  );
};

export default CheckoutPage;
