import React, { useEffect } from 'react';
import { Box, Drawer, Typography, Button } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { useNavigate } from 'react-router-dom';

const ManageSubscription = ({ isOpen, onClose }) => {
  const { currentUser, refreshUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAppStateChange = (state) => {
      if (state.isActive) {
        refreshUser();
      }
    };

    App.addListener('appStateChange', handleAppStateChange);

    return () => {
      App.removeAllListeners();
    };
  }, [refreshUser]);

  const handleManageSubscription = async () => {
    const info = await Device.getInfo();
    let url;
    
    if (info.platform === 'ios') {
      url = 'https://apps.apple.com/account/subscriptions';
    } else if (info.platform === 'android') {
      url = 'https://play.google.com/store/account/subscriptions';
    }

    if (url) {
      await Browser.open({ url });
    }
  };

  const handleStartTrial = () => {
    navigate('/checkout');
  };

  return (
    <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
      <Box sx={{ width: '100%', p: 3, pt: 4, pb: 5 }}>
        {currentUser ? (
          !currentUser.subscriptionStartDate ? (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" sx={{ mb: 2 }} gutterBottom>
                Démarrez votre essai gratuit
              </Typography>
              <Button variant="contained" color="primary" onClick={handleStartTrial}>
                C'est parti
              </Button>
            </Box>
          ) : currentUser.subscriptionStatus === 'active' ? (
            <Box>
              <Typography variant="h6" sx={{ mb: 1 }} gutterBottom>
                Mon abonnement
              </Typography>
              <Typography variant="body1">
                <strong>Statut :</strong> Actif
              </Typography>
              <Typography variant="body1">
                <strong>Date de début :</strong> {new Date(currentUser.subscriptionStartDate).toLocaleDateString()}
              </Typography>
              <Button variant="contained" color="primary" onClick={handleManageSubscription} sx={{ mt: 2 }}>
                Gérer mon abonnement
              </Button>
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" sx={{ mb: 2 }} gutterBottom>
                Abonnement annulé
              </Typography>
              <Button variant="contained" color="primary" onClick={handleStartTrial}>
                Renouveler
              </Button>
            </Box>
          )
        ) : (
          <Typography variant="body1">Chargement...</Typography>
        )}
      </Box>
    </Drawer>
  );
};

export default ManageSubscription;
